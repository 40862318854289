import React from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";

function AssignUserIdModal({
                               isOpen,
                               toggle,
                               handleClose,
                               title,
                               description,
                               inputAssignedUserId,
                               setInputAssignedUserId,
                               handleAssignUserFormSubmit
                           }) {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={handleClose}>
                {title}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>{description}</Label>
                </FormGroup>
                <FormGroup>
                    <Label>Email:</Label>
                    <Input
                        type="text"
                        id="email"
                        value={inputAssignedUserId}
                        onChange={(e) => setInputAssignedUserId(e.target.value)}
                        required
                        autoComplete="email"
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="success"
                    type="submit"
                    disabled={!inputAssignedUserId?.trim()}
                    onClick={handleAssignUserFormSubmit}
                >
                    Asignar
                </Button>
                <Button color="danger" onClick={handleClose}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

AssignUserIdModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    inputAssignedUserId: PropTypes.string,
    setInputAssignedUserId: PropTypes.func.isRequired,
    handleAssignUserFormSubmit: PropTypes.func.isRequired
};

export default AssignUserIdModal;
