import {Button, ButtonGroup, Table} from "reactstrap";
import Spinner from "../../common/Spinner";
import React from "react";
import PropTypes from "prop-types";

const BudgetDetailExtra = ({
                               budgetDetail,
                               loading,
                               isExtraEditable,
                               handleExtraInputChange,
                               editedExtraFields,
                               handleExtraTypeChange,
                               handlePriceChange,
                               handleRemoveExtra,
                               handleAddExtra,
                               handleExtraAccept,
                               handleExtraCancel,
                               handleExtraEdit
                           }) => {

    return (
        <div className="table-container">
            <Table className="mt-4">
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <tr>
                        <td width="1%"></td>
                        <td width="15%">Bosque: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.forestPrice}
                                onChange={(e) => handleExtraInputChange('forestPrice', e.target.value)}
                            /> :
                            budgetDetail.extra.forestPrice}€
                        </td>
                        <td width="15%">Seguro: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.insurancePrice}
                                onChange={(e) => handleExtraInputChange('insurancePrice', e.target.value)}
                            /> :
                            budgetDetail.extra.insurancePrice}€
                        </td>
                        <td width="15%">Regalo: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.giftPrice}
                                onChange={(e) => handleExtraInputChange('giftPrice', e.target.value)}
                            /> :
                            budgetDetail.extra.giftPrice}€
                        </td>
                        <td width="20%">Seguro de cancelación: {isExtraEditable ?
                            <input
                                type="text"
                                value={editedExtraFields?.cancellationInsurance}
                                onChange={(e) => handleExtraInputChange('cancellationInsurance', e.target.value)}
                            /> :
                            budgetDetail.extra.cancellationInsurance}€
                        </td>
                        <td width="18%">Otros: {isExtraEditable ? (
                            <div>
                                {editedExtraFields.extras?.map((extra, index) => (
                                    <div key={`extra_${index}`} className="extra-row">
                                        <input
                                            type="text"
                                            placeholder="Tipo de extra"
                                            value={extra.extraType}
                                            onChange={(e) => handleExtraTypeChange(index, e.target.value)}
                                            className="extra-input"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Precio"
                                            value={extra.price}
                                            onChange={(e) => handlePriceChange(index, e.target.value)}
                                            className="extra-input extra-price-input"
                                        />
                                        <Button color="danger" onClick={() => handleRemoveExtra(index)}>-</Button>
                                    </div>
                                ))}
                                <Button color="success" onClick={handleAddExtra}>+</Button>
                            </div>
                        ) : (
                            <div>
                                {budgetDetail.extra.extras?.map((extra, index) => (
                                    <div key={`extra_${index}`} className="extra-row">
                                        {extra.extraType}: {extra.price}€
                                    </div>
                                ))}
                            </div>
                        )}
                        </td>
                        <td width="7%">
                            {isExtraEditable ? (
                                <ButtonGroup>
                                    <Button size="sm" color="success" onClick={handleExtraAccept}>Aceptar</Button>
                                    <Button size="sm" color="danger" onClick={handleExtraCancel}>Cancelar</Button>
                                </ButtonGroup>
                            ) : (
                                <Button size="sm" color="warning" onClick={handleExtraEdit}>Editar</Button>
                            )}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailExtra.propTypes = {
    budgetDetail: PropTypes.shape({
        extra: PropTypes.shape({
            forestPrice: PropTypes.any,
            insurancePrice: PropTypes.any,
            giftPrice: PropTypes.any,
            cancellationInsurance: PropTypes.any,
            extras: PropTypes.arrayOf(
                PropTypes.shape({
                    extraType: PropTypes.any,
                    price: PropTypes.any,
                })
            ),
        }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    isExtraEditable: PropTypes.bool.isRequired,
    handleExtraInputChange: PropTypes.func.isRequired,
    editedExtraFields: PropTypes.shape({
        forestPrice: PropTypes.any,
        insurancePrice: PropTypes.any,
        giftPrice: PropTypes.any,
        cancellationInsurance: PropTypes.any,
        extras: PropTypes.arrayOf(
            PropTypes.shape({
                extraType: PropTypes.any,
                price: PropTypes.any,
            })
        ),
    }).isRequired,
    handleExtraTypeChange: PropTypes.func.isRequired,
    handlePriceChange: PropTypes.func.isRequired,
    handleRemoveExtra: PropTypes.func.isRequired,
    handleAddExtra: PropTypes.func.isRequired,
    handleExtraAccept: PropTypes.func.isRequired,
    handleExtraCancel: PropTypes.func.isRequired,
    handleExtraEdit: PropTypes.func.isRequired,
};

export default BudgetDetailExtra;
