import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../auth/AuthContext';
import './Login.css';
import {Button} from "reactstrap";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {login, googleSignIn} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const authenticationFailure = localStorage.getItem('authentication_failure');
        if (authenticationFailure === 'true') {
            localStorage.removeItem('authentication_failure');
            window.alert('Fallo de autenticación');
        }
    }, []);

    const handleLogin = async () => {
        await login(username, password, navigate);
    };

    const handleGoogleSignIn = async () => {
        await googleSignIn(navigate);
    };

    return (
        <div className="center-container">
            <div className="form-container">
                <h2>Inicia sesión</h2>
                <form>
                    <div className="label-container">
                        <label>Nombre de usuario<input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}/>
                        </label>
                        <br/>
                        <label>Contraseña<input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                        </label>
                    </div>
                    <br/>
                    <div className="button-container">
                        <Button type="button" onClick={handleLogin}>
                            Entra
                        </Button>
                    </div>
                </form>
            </div>
            <div className="google-signin-container">
                <Button onClick={handleGoogleSignIn}>
                    Iniciar sesión con Google
                </Button>
            </div>
        </div>
    );
};

export default Login;
