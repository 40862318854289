import React, { createContext, useContext, useState, useCallback } from 'react';

const ClickBlockContext = createContext();

export function ClickBlockProvider({ children }) {
    const [isBlocked, setIsBlocked] = useState(false);

    const blockClicks = useCallback(() => setIsBlocked(true), []);
    const unblockClicks = useCallback(() => setIsBlocked(false), []);

    return (
        <ClickBlockContext.Provider value={{ isBlocked, blockClicks, unblockClicks }}>
            {children}
        </ClickBlockContext.Provider>
    );
}

export function useClickBlock() {
    return useContext(ClickBlockContext);
}
