import React, {useEffect, useState} from 'react';
import {useFetchBlob, useFetchData} from "../http/HttpUtil";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";
import {Button, ButtonGroup, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader} from "reactstrap";
import '../common/ModalFullScreen.css';
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {deleteAttachment, downloadAttachment, getAttachments, uploadAttachment} from "../service/AttachmentRestService";

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

function Attachment({entity, id, onClose}) {
    const fetchData = useFetchData();
    const fetchBlob = useFetchBlob();
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const {blockClicks, unblockClicks} = useClickBlock();

    useEffect(() => {
        blockClicks();
        const fetchFiles = async () => {
            try {
                await getAttachments(fetchData, entity, id)
                    .then(data => setFiles(data));
            } catch (error) {
                console.error('Error fetching files: ', error);
            }
        };

        fetchFiles()
            .then(() => {
                unblockClicks();
                setLoading(false);
            });

    }, [entity, id, fetchData, blockClicks, unblockClicks]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);

        if (oversizedFiles.length > 0) {
            const message = oversizedFiles.length === 1 ?
                `El fichero no puede tener más de ${MAX_FILE_SIZE_MB}MB` :
                `Los ficheros no pueden tener más de ${MAX_FILE_SIZE_MB}MB`;
            setErrorMessage(message);
            setSelectedFiles(null);
        } else {
            setErrorMessage('');
            setSelectedFiles(event.target.files);
        }
    };

    const handleUpload = async () => {
        blockClicks();
        try {
            const formData = new FormData();

            Array.from(selectedFiles).forEach((file) => formData.append(`files`, file));

            uploadAttachment(fetchData, entity, id, formData)
                .then(() => {
                    setSelectedFiles(null);
                    window.location.reload();
                });
        } catch (error) {
            console.error('Error uploading file: ', error);
        }
        unblockClicks();
    };

    const handleDelete = async (path) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este archivo?');
        if (confirmDelete) {
            blockClicks();
            try {
                await deleteAttachment(fetchData, path)
                    .then(() => window.location.reload());
            } catch (error) {
                console.error('Error deleting file: ', error);
            } finally {
                unblockClicks();
            }
        }
    };

    const handleDownload = async (file) => {
        try {
            blockClicks();
            await downloadAttachment(fetchBlob, file.path)
                .then(async blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        } catch (error) {
            console.error('Error downloading file: ', error);
        } finally {
            unblockClicks();
        }
    };

    return (
        <div>
            <ClickBlockOverlay/>
            <Modal isOpen={true} toggle={onClose} className="modal-fullscreen">
                <ModalHeader toggle={onClose}>Ficheros adjuntos</ModalHeader>
                <ModalBody>
                    {loading ? <Spinner/> : (
                        <ListGroup>
                            {files?.map((file, index) => (
                                <ListGroupItem key={file.path + '_' + index}
                                               className="d-flex justify-content-between align-items-center">
                                    {file.filename}
                                    <ButtonGroup>
                                        <Button size="sm" color="primary"
                                                onClick={() => handleDownload(file)}>Descargar</Button>
                                        <Button size="sm" color="danger"
                                                disabled={file.readOnly}
                                                onClick={() => handleDelete(file.path)}>Eliminar</Button>
                                    </ButtonGroup>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                    <div className="mt-3">
                        <Input type="file" multiple onChange={handleFileChange}/>
                        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                        <Button color="success" disabled={!selectedFiles} onClick={handleUpload}>Subir</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

Attachment.propTypes = {
    entity: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Attachment;
