import React, {useCallback, useEffect, useState} from 'react';
import ScheduleCalendar from './ScheduleCalendar';
import {useFetchData} from "../http/HttpUtil";
import Spinner from "../common/Spinner";
import {Input, Label} from "reactstrap";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {getSchedules} from "../service/ScheduleRestService";

const ScheduleView = () => {
    const fetchData = useFetchData();
    const [schedules, setSchedules] = useState([]);
    const [byUser, setByUser] = useState(true);
    const [loading, setLoading] = useState(false);
    const {blockClicks, unblockClicks} = useClickBlock();

    const fetchSchedules = useCallback(() => {
        blockClicks();
        setLoading(true);

        getSchedules(fetchData, byUser)
            .then(data => {
                setSchedules(data.content);
                unblockClicks();
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching schedules:', error);
                unblockClicks();
                setLoading(false);
            });
    }, [byUser, fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchSchedules();
    }, [fetchSchedules, byUser, blockClicks, unblockClicks]);

    const handleCheckboxChange = () => {
        setByUser(!byUser);
    };

    if (loading) {
        return <Spinner/>;
    }

    const parsedEvents = schedules?.map(schedule => ({
        ...schedule,
        start: new Date(schedule.start),
        end: new Date(schedule.end),
    }));

    return (
        <div>
            <ClickBlockOverlay/>
            <div className="d-flex justify-content-between align-items-center">
                <h3>Calendario</h3>
                <div className="col d-flex justify-content-center">
                    <Input
                        type="checkbox"
                        id="byUserCheckbox"
                        checked={byUser}
                        onChange={handleCheckboxChange}
                    />
                    <Label for="byUserCheckbox" className="ms-2">
                        Mis eventos
                    </Label>
                </div>
            </div>
            <ScheduleCalendar events={parsedEvents} byUser={byUser}/>
        </div>
    );
};

export default ScheduleView;
