import {Button, ButtonGroup, Input, Table} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {getBudgetDetailType} from "./BudgetDetailModel";

const BudgetDetailTask = ({
                              budgetDetail,
                              editedTaskFields,
                              getKey,
                              handleTaskCheck,
                              handleTaskCategoryChange,
                              handleTaskAccept,
                              handleTaskDelete,
                              handleAddTask,
                              getTaskCategory,
                              handleTaskStatusChange,
                          }) => {

    const budgetDetailTypes = getBudgetDetailType();

    return (
        <div className="d-flex align-content-end justify-content-end">
            <div style={{width: '90%', float: 'right'}}>
                <Table style={{width: '99%', borderCollapse: 'collapse', border: '1px solid black'}}>
                    <tbody>
                    {editedTaskFields?.reduce((pairs, item, index) => {
                        if (index % 2 === 0) {
                            pairs.push([item]);
                        } else {
                            pairs[pairs.length - 1].push(item);
                        }
                        return pairs;
                    }, []).map((pair, index) => (
                        <tr key={`${pair[0]?.id}-${pair[1]?.id}`}>
                            <td style={{border: '1px solid black', padding: '8px', width: '50%'}}>
                                {index < 5 ? (
                                    <>
                                        <a href={`/budgets/${budgetDetail.id}/details/${pair[0]?.id}?type=${getKey(budgetDetailTypes.TASK, budgetDetailTypes)}`}>
                                            <strong>{getTaskCategory(pair[0])}</strong>
                                        </a>
                                        {' '}
                                        <Input
                                            type="checkbox"
                                            checked={pair[0]?.status}
                                            onChange={(e) => handleTaskCheck(pair[0], e.target.checked)}
                                        />
                                    </>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Input
                                            type="text"
                                            value={pair[0]?.taskCategory}
                                            style={{width: '50%'}}
                                            onChange={(e) => handleTaskCategoryChange(index * 2, e.target.value)}
                                            autoComplete="taskCategory"
                                            maxLength={255}
                                        />
                                        <Input
                                            type="checkbox"
                                            checked={pair[0]?.status}
                                            onChange={(e) => {
                                                pair[0].id ? handleTaskCheck(pair[0], e.target.checked) : handleTaskStatusChange(index * 2, e.target.checked)
                                            }}
                                        />
                                        <ButtonGroup>
                                            <Button color="success" size="sm"
                                                    onClick={() => handleTaskAccept()}>Guardar</Button>
                                            <Button color="danger" size="sm"
                                                    onClick={() => handleTaskDelete(index * 2, pair[0].id)}>Eliminar</Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                            </td>
                            <td style={{border: '1px solid black', padding: '8px', width: '50%'}}>
                                {index < 5 ? (
                                    <>
                                        <a href={`/budgets/${budgetDetail.id}/details/${pair[1]?.id}?type=${getKey(budgetDetailTypes.TASK, budgetDetailTypes)}`}>
                                            <strong>{getTaskCategory(pair[1])}</strong>
                                        </a>
                                        {' '}
                                        <Input
                                            type="checkbox"
                                            checked={pair[1]?.status}
                                            onChange={(e) => handleTaskCheck(pair[1], e.target.checked)}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {pair[1] && (
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Input
                                                    type="text"
                                                    value={pair[1]?.taskCategory}
                                                    style={{width: '50%'}}
                                                    onChange={(e) => handleTaskCategoryChange(index * 2 + 1, e.target.value)}
                                                    autoComplete="taskCategory"
                                                />
                                                <Input
                                                    type="checkbox"
                                                    checked={pair[1]?.status}
                                                    onChange={(e) => {
                                                        pair[1].id ? handleTaskCheck(pair[1], e.target.checked) : handleTaskStatusChange(index * 2 + 1, e.target.checked)
                                                    }}
                                                />
                                                <ButtonGroup>
                                                    <Button color="success" size="sm"
                                                            onClick={() => handleTaskAccept()}>Guardar</Button>
                                                    <Button color="danger" size="sm"
                                                            onClick={() => handleTaskDelete(index * 2 + 1, pair[1].id)}>Eliminar</Button>
                                                </ButtonGroup>
                                            </div>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
            <div>
                <Button color="primary" size="sm" onClick={handleAddTask}>Añadir
                </Button>
            </div>
        </div>
    );
};

BudgetDetailTask.propTypes = {
    budgetDetail: PropTypes.shape({
        id: PropTypes.any.isRequired,
    }).isRequired,
    editedTaskFields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            taskCategory: PropTypes.any,
            status: PropTypes.any,
        })
    ),
    getKey: PropTypes.func.isRequired,
    handleTaskCheck: PropTypes.func.isRequired,
    handleTaskCategoryChange: PropTypes.func.isRequired,
    handleTaskAccept: PropTypes.func.isRequired,
    handleTaskDelete: PropTypes.func.isRequired,
    handleAddTask: PropTypes.func.isRequired,
    getTaskCategory: PropTypes.func.isRequired,
    handleTaskStatusChange: PropTypes.func.isRequired,
};

export default BudgetDetailTask;
