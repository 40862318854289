import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, FormGroup, Table} from 'reactstrap';
import '../common/Common.css';
import {useFetchData} from "../http/HttpUtil";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";
import PaginationContainer, {defaultPageParams} from "../common/PaginationContainer";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatTimestampToDate} from "../common/Utils";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {getCustomers, linkCustomers} from "../service/CustomerRestService";

const LinkCustomers = ({expedientId, travelcatId, initialCustomerIds}) => {
    const fetchData = useFetchData();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageInfo, setPageInfo] = useState(defaultPageParams);
    const {blockClicks, unblockClicks} = useClickBlock();

    const fetchCustomers = useCallback((pageNumber, searchText) => {
        setLoading(true);
        blockClicks();
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageInfo.pageSize);
        if (searchText) {
            queryParams.append('criteria', searchText);
        }

        getCustomers(fetchData, queryParams)
            .then(data => {
                setCustomers(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                setSelectedCustomerIds(initialCustomerIds || []);
                unblockClicks();
                setLoading(false);
            });
    }, [pageInfo.pageSize, initialCustomerIds, fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchCustomers(0, null);
    }, [fetchCustomers, blockClicks, unblockClicks]);

    const handlePageChange = (newPage) => {
        setPageInfo((p) => ({
            ...p,
            pageNumber: newPage,
        }));
        fetchCustomers(newPage, searchText);
    };

    const toggleCheckbox = (customerId) => {
        setSelectedCustomerIds((prevIds) => {
            if (prevIds.includes(customerId)) {
                return prevIds.filter((id) => id !== customerId);
            } else {
                return [...prevIds, customerId];
            }
        });
    };

    const handleSave = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar los clientes seleccionados al expediente ${travelcatId}?`);

        if (confirmAssign) {
            blockClicks();
            await linkCustomers(fetchData, expedientId, selectedCustomerIds)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleApplySearch = () => {
        setLoading(true);
        fetchCustomers(0, searchText);
    };

    const handleClearFilters = () => {
        setLoading(true);
        setSearchText('')
        fetchCustomers(0, null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleApplySearch();
        }
    };

    const handleReload = () => {
        setLoading(true);
        fetchCustomers(0, searchText);
    };

    if (loading) {
        return <Spinner/>;
    }

    const customerList = () => {
        return (
            <React.Fragment>
                {customers.map((customer) => (
                    <tr key={customer.id}>
                        <td>
                            <input
                                type="checkbox"
                                checked={selectedCustomerIds.includes(customer.id)}
                                onChange={() => toggleCheckbox(customer.id)}
                            />
                        </td>
                        <td>{customer.firstName} {customer.firstSurname} {customer.secondSurname}</td>
                        <td>{customer.phone}</td>
                        <td>{customer.email}</td>
                        <td>{customer.idNumber}</td>
                        <td>{customer.passport}</td>
                        <td>{formatTimestampToDate(customer.birthDate)}</td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };

    return (
        <div>
            <ClickBlockOverlay/>
            <Container fluid>
                <div className="d-flex justify-content-between align-items-center">
                    <h3>Asignar clientes a expediente {travelcatId}</h3>
                    <div className="d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Buscar cliente"
                            value={searchText}
                            size="sm"
                            onChange={handleSearchInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <ButtonGroup>
                            <Button color="warning" size="sm" onClick={handleApplySearch}>Aplicar</Button>
                            <Button color="secondary" size="sm" onClick={handleClearFilters}>Reiniciar</Button>
                        </ButtonGroup>
                    </div>
                    <div className="d-flex align-items-center">
                        <Button color="link" onClick={handleReload} className="p-0 me-3">
                            <FontAwesomeIcon icon={faRedo} size="lg"/>
                        </Button>
                        <a href="/customers/new" target="_blank" rel="noopener noreferrer">
                            <Button color="primary">Añadir cliente</Button>
                        </a>
                    </div>
                </div>
                <Table className="mt-4 table-striped table-hover">
                    <thead>
                    <tr>
                        <th width="5%">Seleccionar</th>
                        <th width="18%">Nombre y apellidos</th>
                        <th width="8%">Teléfono</th>
                        <th width="8%">Email</th>
                        <th width="7%">DNI</th>
                        <th width="7%">Pasaporte</th>
                        <th width="8%">Fecha de nacimiento</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customerList()}
                    </tbody>
                </Table>
                <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
                <FormGroup>
                    <Button color="success" size="sm" type="submit" onClick={handleSave}>Guardar</Button>
                </FormGroup>
            </Container>
        </div>
    );
};

LinkCustomers.propTypes = {
    expedientId: PropTypes.number.isRequired,
    travelcatId: PropTypes.string.isRequired,
    initialCustomerIds: PropTypes.array.isRequired
};

export default LinkCustomers;
