import {Button, ButtonGroup, Table} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {formatBooleanUppercase, formatTimestampToDate, getKey} from "../../common/Utils";
import CharacterCounter from "../../common/CharacterCounter";
import {getBudgetDetailType} from "./BudgetDetailModel";

const BudgetDetailFinancing = ({
                                   budgetDetail,
                                   isFinancingEditable,
                                   editedFinancingFields,
                                   handlePaymentStatusChange,
                                   handleFinancingInputChange,
                                   handleFinancingAccept,
                                   handleFinancingCancel,
                                   handleFinancingEdit,
                                   remove,
                               }) => {

    const budgetDetailTypes = getBudgetDetailType();
    const {totalPrice, financingPayments, comments, schedule, signed} = budgetDetail.financing;

    return (
        <div style={{width: '45%', float: 'left', marginLeft: '10%'}}>
            <Table style={{width: '80%', borderCollapse: 'collapse', border: '1px solid black'}}>
                <tbody>
                <tr>
                    <td colSpan="4"
                        style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <strong>FINANCIACIÓN</strong>: {totalPrice}€
                    </td>
                    <td colSpan="1"
                        style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <Button size="sm" color="primary" tag={Link}
                                to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.FINANCING, budgetDetailTypes)}`}>
                            Añadir</Button>
                    </td>
                </tr>
                {financingPayments?.slice()
                    .sort((a, b) => a.position - b.position)
                    .map((payment) => (
                        <tr key={payment.id}>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{payment.position}o
                            </td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{formatTimestampToDate(payment.date)}</td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{payment.total}€
                            </td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>
                                <input
                                    type="checkbox"
                                    checked={payment.status}
                                    onChange={(e) => handlePaymentStatusChange(payment, e.target.checked)}
                                />
                            </td>
                            <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                <ButtonGroup>
                                    <Button size="sm" color="warning" tag={Link}
                                            to={`/budgets/${budgetDetail.id}/details/${payment.id}?type=${getKey(budgetDetailTypes.FINANCING, budgetDetailTypes)}`}>Editar</Button>
                                    <Button size="sm" color="danger"
                                            onClick={() => remove(budgetDetailTypes.FINANCING, payment.id)}>Eliminar</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                <tr>
                    <td colSpan="4"
                        style={{border: '1px solid black', padding: '8px', textAlign: 'left'}}>
                        <strong>Observaciones</strong>: {isFinancingEditable ? (
                            <>
                                <input
                                    type="text"
                                    value={editedFinancingFields?.comments}
                                    onChange={(e) => handleFinancingInputChange('comments', e.target.value)}
                                    maxLength={4000}
                                />
                                <CharacterCounter
                                    currentLength={editedFinancingFields?.comments?.length}
                                    maxLength={4000}
                                />
                            </>
                        ) :
                        comments}
                    </td>
                    <td colSpan="1"
                        style={{padding: '8px', textAlign: 'center'}}>
                        {isFinancingEditable ? (
                            <ButtonGroup>
                                <Button size="sm" color="success" onClick={handleFinancingAccept}>Aceptar</Button>
                                <Button size="sm" color="danger" onClick={handleFinancingCancel}>Cancelar</Button>
                            </ButtonGroup>
                        ) : (
                            <Button color="warning" onClick={handleFinancingEdit}>Editar</Button>
                        )}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" style={{border: '1px solid black', padding: '8px'}}>
                        <strong>AGENDAR</strong>: {isFinancingEditable ?
                        <input
                            type="checkbox"
                            checked={editedFinancingFields?.schedule}
                            onChange={(e) => handleFinancingInputChange('schedule', e.target.checked)}
                        /> :
                        formatBooleanUppercase(schedule)}
                    </td>
                    <td colSpan="2" style={{border: '1px solid black', padding: '8px'}}>
                        <strong>FIRMA CONTRATO</strong>: {isFinancingEditable ?
                        <input
                            type="checkbox"
                            checked={editedFinancingFields?.signed}
                            onChange={(e) => handleFinancingInputChange('signed', e.target.checked)}
                        /> :
                        formatBooleanUppercase(signed)}
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailFinancing.propTypes = {
    budgetDetail: PropTypes.shape({
        id: PropTypes.any.isRequired,
        financing: PropTypes.shape({
            totalPrice: PropTypes.any.isRequired,
            financingPayments: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.any.isRequired,
                    position: PropTypes.any.isRequired,
                    date: PropTypes.any.isRequired,
                    total: PropTypes.any.isRequired,
                    status: PropTypes.any.isRequired,
                })
            ).isRequired,
            comments: PropTypes.any,
            schedule: PropTypes.any.isRequired,
            signed: PropTypes.any.isRequired,
        }).isRequired,
    }).isRequired,
    isFinancingEditable: PropTypes.any.isRequired,
    editedFinancingFields: PropTypes.shape({
        comments: PropTypes.any,
        schedule: PropTypes.any,
        signed: PropTypes.any,
    }),
    handlePaymentStatusChange: PropTypes.func.isRequired,
    handleFinancingInputChange: PropTypes.func.isRequired,
    handleFinancingAccept: PropTypes.func.isRequired,
    handleFinancingCancel: PropTypes.func.isRequired,
    handleFinancingEdit: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
};
export default BudgetDetailFinancing;
