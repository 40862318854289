import React, {useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useFetchData} from "../../http/HttpUtil";
import PropTypes from "prop-types";
import {ClickBlockOverlay} from "../../common/ClickBlockOverlay";
import {useClickBlock} from "../../context/ClickBlockContext";
import {generateExpedient, validateExpedient} from "../../service/ExpedientRestService";

function ExpedientModal({
                            isOpen,
                            toggle,
                            onConfirm,
                            budgetId,
                            defaultAssignedUserId,
                            defaultExpedientId,
                            defaultTravelcatId
                        }) {
    const fetchData = useFetchData();
    const [assignedUserId, setAssignedUserId] = useState(defaultAssignedUserId || '');
    const [travelcatId, setTravelcatId] = useState(defaultTravelcatId || '');
    const [validatedTravelcatId, setValidatedTravelcatId] = useState(defaultTravelcatId || '');
    const [expedientId, setExpedientId] = useState(defaultExpedientId || '');
    const [travelcatLocationId, setTravelcatLocationId] = useState('B');
    const {blockClicks, unblockClicks} = useClickBlock();

    const handleTravelcatIdChange = (event) => {
        const {value} = event.target;
        setTravelcatId(value);
        setValidatedTravelcatId('');
    };

    const handleTravelcatLocationIdChange = (event) => {
        setTravelcatLocationId(event.target.value);
    };

    const handleGenerateExpedient = async () => {
        blockClicks();
        const data = await generateExpedient(fetchData, travelcatLocationId);

        setExpedientId(data.id);
        setTravelcatId(data.travelcatId);
        setValidatedTravelcatId(data.travelcatId);
        unblockClicks();
    };

    const handleValidateExpedient = async () => {
        if (!travelcatId) {
            window.alert('Introduce un valor en Expediente');
            return;
        }
        blockClicks();

        const data = await validateExpedient(fetchData, travelcatId);

        if (data) {
            setExpedientId(data.id);
            setValidatedTravelcatId(data.travelcatId);
        }
        unblockClicks();
    };

    const handleConfirm = () => {
        onConfirm(budgetId, assignedUserId, expedientId);
    };

    return (
        <>
            <ClickBlockOverlay/>
            <Modal isOpen={isOpen} toggle={toggle} size="l">
                <ModalHeader toggle={toggle}>Duplicar presupuesto</ModalHeader>
                <ModalBody>
                    <FormGroup className="row align-items-center">
                        <Label for="travelcatId">Expediente</Label>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                name="expedient.travelcatId"
                                id="travelcatId"
                                value={travelcatId}
                                onChange={handleTravelcatIdChange}
                                autoComplete="travelcatId"
                                required
                            />
                        </div>
                        <div className="col-md-2">
                            <Button
                                color="primary"
                                onClick={handleValidateExpedient}
                                disabled={validatedTravelcatId === travelcatId}
                            >
                                Validar
                            </Button>
                        </div>
                        <div className="col-md-3">
                            <Button color="primary"
                                    onClick={handleGenerateExpedient}
                            >
                                Generar
                            </Button>
                        </div>
                        <div className="col-md-4">
                            <Input
                                type="select"
                                name="travelcatLocationId"
                                id="travelcatLocationId"
                                value={travelcatLocationId}
                                onChange={handleTravelcatLocationIdChange}
                            >
                                <option value="B">Barcelona</option>
                                <option value="V">Valencia</option>
                                <option value="M">Madrid</option>
                                <option value="Z">Zaragoza</option>
                            </Input>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Asignado a (solamente si es nuevo)</Label>
                        <Input
                            type="text"
                            id="email"
                            value={assignedUserId}
                            onChange={(e) => setAssignedUserId(e.target.value)}
                            required
                            autoComplete="email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="col-md-12 mt-3">
                            <Button color="success"
                                    onClick={handleConfirm}
                                    disabled={!expedientId || !validatedTravelcatId}
                            >
                                Confirmar
                            </Button>
                        </div>
                    </FormGroup>
                </ModalBody>
            </Modal>
        </>
    );
}

ExpedientModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    budgetId: PropTypes.number.isRequired,
    defaultAssignedUserId: PropTypes.string.isRequired,
    defaultExpedientId: PropTypes.number.isRequired,
    defaultTravelcatId: PropTypes.string.isRequired
};

export default ExpedientModal;
