import {Button, ButtonGroup, Table} from "reactstrap";
import Spinner from "../../common/Spinner";
import React from "react";
import PropTypes from "prop-types";

const BudgetDetailAccounting = ({
                                    budgetDetail,
                                    loading,
                                    isAccountingEditable,
                                    editedAccountingFields,
                                    handleAccountingFieldsChange,
                                    handleAccountingFieldsAccept,
                                    handleAccountingFieldsCancel,
                                    handleAccountingEdit
                                }) => {

    return (
        <div className="table-container">
            <Table className="mt-4">
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <tr>
                        <td></td>
                        <td width="20%"><strong>NETO</strong>: {budgetDetail.accounting.price}€</td>
                        {!editedAccountingFields.pricePerFamilyEnabled && (
                            <td width="20%">
                                <strong>PVP</strong>: {isAccountingEditable ? (
                                <input
                                    type="text"
                                    value={editedAccountingFields.pvp}
                                    onChange={(e) => handleAccountingFieldsChange('pvp', e.target.value)}
                                />
                            ) : (
                                budgetDetail.accounting.pvp
                            )}€/PAX
                            </td>
                        )}
                        {editedAccountingFields.pricePerFamilyEnabled && (
                            <td width="20%">
                                <strong>Precio por
                                    familia</strong>: {isAccountingEditable ? (
                                <input
                                    type="text"
                                    value={editedAccountingFields.pricePerFamily}
                                    onChange={(e) => handleAccountingFieldsChange('pricePerFamily', e.target.value)}
                                />
                            ) : (
                                budgetDetail.accounting.pricePerFamily
                            )}€
                            </td>
                        )}
                        <td width="13%">
                            <div>
                                <span>Por familia</span>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    disabled={!isAccountingEditable}
                                    checked={editedAccountingFields.pricePerFamilyEnabled}
                                    onChange={(e) => handleAccountingFieldsChange('pricePerFamilyEnabled', e.target.checked)}
                                />
                            </div>
                        </td>
                        <td width="20%">
                            <strong>TOTAL</strong>: {isAccountingEditable ? (
                            <input
                                type="text"
                                value={editedAccountingFields.total}
                                onChange={(e) => handleAccountingFieldsChange('total', e.target.value)}
                            />
                        ) : (
                            budgetDetail.accounting.total
                        )}€
                        </td>
                        <td width="20%"><strong>BENEFICIO</strong>: {budgetDetail.accounting.benefit}€</td>
                        <td width="7%">
                            {isAccountingEditable ? (
                                <>
                                    {'  '}
                                    <ButtonGroup>
                                        <Button size="sm" color="success"
                                                onClick={handleAccountingFieldsAccept}>Aceptar</Button>
                                        <Button size="sm" color="danger"
                                                onClick={handleAccountingFieldsCancel}>Cancelar</Button>
                                    </ButtonGroup>
                                </>
                            ) : (
                                <>
                                    {'  '}
                                    <Button size="sm" color="warning" onClick={handleAccountingEdit}>Editar</Button>
                                </>
                            )}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailAccounting.propTypes = {
    budgetDetail: PropTypes.shape({
        accounting: PropTypes.shape({
            price: PropTypes.any.isRequired,
            pvp: PropTypes.any.isRequired,
            pricePerFamily: PropTypes.any.isRequired,
            total: PropTypes.any.isRequired,
            benefit: PropTypes.any.isRequired,
        }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    isAccountingEditable: PropTypes.bool.isRequired,
    editedAccountingFields: PropTypes.shape({
        pvp: PropTypes.any,
        pricePerFamily: PropTypes.any,
        pricePerFamilyEnabled: PropTypes.any,
        total: PropTypes.any,
    }).isRequired,
    handleAccountingFieldsChange: PropTypes.func.isRequired,
    handleAccountingFieldsAccept: PropTypes.func.isRequired,
    handleAccountingFieldsCancel: PropTypes.func.isRequired,
    handleAccountingEdit: PropTypes.func.isRequired,
};

export default BudgetDetailAccounting;
