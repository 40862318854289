import React from 'react';
import {useClickBlock} from '../context/ClickBlockContext';
import './ClickBlockOverlay.css';
import Spinner from "./Spinner";

export function ClickBlockOverlay() {
    const {isBlocked} = useClickBlock();

    return (
        isBlocked && (
            <div className="click-block-overlay">
                <Spinner/>
            </div>
        )
    );
}
