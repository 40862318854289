import {BASE_API_URL} from "./RestService";

export function getBudgets(fetchData, queryParams) {
    return fetchData(`${BASE_API_URL}/budget/resources?${queryParams.toString()}`, {});
}

export function duplicateBudget(fetchData, budgetId, assignedUserId, expedientId) {
    return fetchData(`${BASE_API_URL}/budget/duplicate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({budgetId: budgetId, assignedUserId: assignedUserId, expedientId: expedientId}),
    });
}

export function assignUser(fetchData, assignUserBudgetId, inputAssignedUserId) {
    return fetchData(`${BASE_API_URL}/budget/${assignUserBudgetId}/assign-user`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId: inputAssignedUserId}),
    });
}

export function deleteBudget(fetchData, id) {
    return fetchData(`${BASE_API_URL}/budget/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function upsertBudget(fetchData, budget) {
    return fetchData(`${BASE_API_URL}/budget`, {
        method: budget.id ? 'PUT' : 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(budget),
    });
}
