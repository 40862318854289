export const getKey = (value, object) => Object.keys(object).find(key => object[key] === value);

export function inList(value, ...list) {
    return list.includes(value);
}

export function getDefaultDate(defaultDate, additionalTime) {
    const year = defaultDate.getFullYear();
    const month = (defaultDate.getMonth() + 1).toString().padStart(2, '0');
    const day = defaultDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}${additionalTime}`;
}

export const formatTimestampToDateTime = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        let options;
        if (date.getHours() === 23 && date.getMinutes() === 59) {
            options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            };
        } else {
            options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
        }
        return date.toLocaleDateString('es-ES', options);
    }
    return null;
};

export const formatTimestampToDate = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };
        return date.toLocaleDateString('es-ES', options);
    }
    return null;
};

export const isTodayOrTomorrow = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        const today = atStartDay(new Date());

        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return true;
        }

        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return (
            date.getDate() === tomorrow.getDate() &&
            date.getMonth() === tomorrow.getMonth() &&
            date.getFullYear() === tomorrow.getFullYear()
        );
    }
    return false;
};

export const isPastDate = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        const today = atStartDay(new Date());

        return date < today;
    }
    return false;
};

export const formatBoolean = (boolean) => {
    return boolean ? 'Sí' : 'No';
};

export const formatBooleanUppercase = (boolean) => {
    return formatBoolean(boolean).toUpperCase();
};

export function isNonTechnicalField(field) {
    return field !== 'id' &&
        field !== 'version' &&
        field !== 'createdBy' &&
        field !== 'creationTimestamp' &&
        field !== 'lastUpdatedBy' &&
        field !== 'lastUpdatedTimestamp';
}

export function calculateNumberOfPeople(people) {
    return (people?.numberOfAdults || 0) +
        (people?.numberOfBabies || 0) +
        (people?.kids?.length || 0);
}

export function createDateWithoutTimezone(selectedDate) {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    const seconds = selectedDate.getSeconds();
    return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
}

export function atStartDay(selectedDate) {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    return new Date(Date.UTC(year, month, day, 0, 0, 0));
}

export function isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
}
