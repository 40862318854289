import {BASE_API_URL} from "./RestService";

export function getAuditData(fetchData, entity, id, queryParams) {
    return fetchData(`${BASE_API_URL}/${entity}/audit/${id}?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });
}
