import {Button, Table} from "reactstrap";
import Spinner from "../../common/Spinner";
import {formatTimestampToDate} from "../../common/Utils";
import React from "react";
import PropTypes from "prop-types";

const BudgetDetailCustomers = ({budgetDetail, loading, handleShowLinkCustomers, getLinkCustomerModal}) => {

    return (
        <div className="table-container">
            <Table className="mt-4">
                <thead>
                <tr>
                    <th colSpan="8">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <h5>Clientes asignados</h5>
                            <div>
                                <Button size="sm" color="warning"
                                        onClick={() => handleShowLinkCustomers(budgetDetail.expedient)}>
                                    Vincular clientes
                                </Button>
                                {getLinkCustomerModal()}
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th width="25%">Nombre y apellidos</th>
                    <th width="15%">Teléfono</th>
                    <th width="15%">Email</th>
                    <th width="15%">DNI</th>
                    <th width="15%">Pasaporte</th>
                    <th width="15%">Fecha de nacimiento</th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    budgetDetail.expedient.customers?.map((customer) => (
                        <tr key={customer.id}>
                            <td>{customer.firstName} {customer.firstSurname} {customer.secondSurname}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.email}</td>
                            <td>{customer.idNumber}</td>
                            <td>{customer.passport}</td>
                            <td>{formatTimestampToDate(customer.birthDate)}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailCustomers.propTypes = {
    budgetDetail: PropTypes.shape({
        expedient: PropTypes.shape({
            customers: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.any.isRequired,
                    firstName: PropTypes.any,
                    firstSurname: PropTypes.any,
                    secondSurname: PropTypes.any,
                    phone: PropTypes.any,
                    email: PropTypes.any,
                    idNumber: PropTypes.any,
                    passport: PropTypes.any,
                    birthDate: PropTypes.any,
                })
            ),
        }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    handleShowLinkCustomers: PropTypes.func.isRequired,
    getLinkCustomerModal: PropTypes.func.isRequired,
};

export default BudgetDetailCustomers;
