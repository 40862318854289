import {useCallback} from 'react';
import {useAuth} from '../../auth/AuthContext';
import {addToken} from '../HttpUtil';

export const useFetchWithTokenRefresh = () => {
    const {refreshAccessToken} = useAuth();

    const refreshAccessTokenAndRetry = useCallback(async (originalRequest) => {
        const newAccessToken = await refreshAccessToken();

        if (newAccessToken) {
            addToken(originalRequest.options);
            return fetch(originalRequest.url, originalRequest.options);
        } else {
            window.location.href = '/login';
        }
    }, [refreshAccessToken]);

    return useCallback(async (url, options) => {
        const originalRequest = {url, options};

        const response = await fetch(url, options);

        if (response.status === 419) {
            return refreshAccessTokenAndRetry(originalRequest);
        }

        return response;
    }, [refreshAccessTokenAndRetry]);
};
