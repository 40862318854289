import {BASE_API_URL} from "./RestService";

function assignUser(fetchData, assignUserExpedientId, endpoint, inputAssignedUserId) {
    return fetchData(`${BASE_API_URL}/expedient/${assignUserExpedientId}/${endpoint}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId: inputAssignedUserId}),
    });
}

export function assignSellerUser(fetchData, assignUserExpedientId, inputAssignedUserId) {
    return assignUser(fetchData, assignUserExpedientId, 'assign-seller-user', inputAssignedUserId);
}

export function assignPlannerUser(fetchData, assignUserExpedientId, inputAssignedUserId) {
    return assignUser(fetchData, assignUserExpedientId, 'assign-planner-user', inputAssignedUserId);
}

export function generateExpedient(fetchData, travelcatLocationId) {
    return fetchData(`${BASE_API_URL}/expedient/generate/${travelcatLocationId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

export function validateExpedient(fetchData, travelcatId) {
    return fetchData(`${BASE_API_URL}/expedient/travelcatId/${travelcatId}`, {});
}

export function getExpedients(fetchData, queryParams) {
    return fetchData(`${BASE_API_URL}/expedient/resources?${queryParams.toString()}`, {});
}

export function getExpedient(fetchData, id) {
    return fetchData(`${BASE_API_URL}/expedient/${id}`, {});
}

export function updateExpedient(fetchData, expedient) {
    return fetchData(`${BASE_API_URL}/expedient`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(expedient),
    });
}
