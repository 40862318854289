import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../common/Common.css';
import {useFetchData} from "../http/HttpUtil";
import Spinner from "../common/Spinner";
import Attachment from "../attachment/Attachment";
import {formatTimestampToDate} from "../common/Utils";
import AuditResponseWidget from "../budget/audit/AuditResponseWidget";
import PaginationContainer, {defaultPageParams} from "../common/PaginationContainer";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {getCustomers} from "../service/CustomerRestService";

const CustomerList = () => {
    const fetchData = useFetchData();
    const [customers, setCustomers] = useState([]);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [openAudit, setOpenAudit] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState(defaultPageParams);
    const {blockClicks, unblockClicks} = useClickBlock();

    const fetchCustomers = useCallback((pageNumber, searchText) => {
        setLoading(true);
        blockClicks();
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageInfo.pageSize);
        if (searchText) {
            queryParams.append('criteria', searchText);
        }

        getCustomers(fetchData, queryParams)
            .then(data => {
                setCustomers(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                unblockClicks();
                setLoading(false);
            });
    }, [pageInfo.pageSize, fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchCustomers(0, null);
    }, [fetchCustomers, blockClicks, unblockClicks]);

    const handlePageChange = (newPage) => {
        setPageInfo((p) => ({
            ...p,
            pageNumber: newPage,
        }));
        fetchCustomers(newPage, searchText);
    };

    const handleToggleDropdown = (customerId) => {
        if (openDropdownId === customerId) {
            setOpenDropdownId(null);
        } else {
            setOpenDropdownId(customerId);
        }
    };

    const handleShowAudit = (customerId) => {
        setSelectedCustomerId(customerId);
        setOpenAudit(true);
    };

    const handleShowAttachment = (customerId) => {
        setSelectedCustomerId(customerId);
        setShowAttachment(true);
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleApplySearch = () => {
        setLoading(true);
        fetchCustomers(0, searchText);
    };

    const handleClearFilters = () => {
        setLoading(true);
        setSearchText('')
        fetchCustomers(0, null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleApplySearch();
        }
    };

    if (loading) {
        return <Spinner/>;
    }

    const customerList = () => {
        return (
            <React.Fragment>
                {customers.map(customer => (
                    <tr key={customer.id}>
                        <td>{customer.firstName}</td>
                        <td>{customer.firstSurname} {customer.secondSurname}</td>
                        <td>{customer.phone}</td>
                        <td>{customer.email}</td>
                        <td>{customer.age}</td>
                        <td>{customer.idNumber}</td>
                        <td>{customer.passport}</td>
                        <td>{formatTimestampToDate(customer.birthDate)}</td>
                        <td>
                            <Dropdown isOpen={openDropdownId === customer.id}
                                      toggle={() => handleToggleDropdown(customer.id)}>
                                <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to={`/customers/${customer.id}`}>
                                        Editar
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleShowAudit(customer.id)}>
                                        Registros
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleShowAttachment(customer.id)}>
                                        Ficheros
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };

    return (
        <div>
            <ClickBlockOverlay/>
            <Container fluid>
                <div className="d-flex justify-content-between align-items-center">
                    <h3>Clientes</h3>
                    <div className="d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Buscar cliente"
                            value={searchText}
                            onChange={handleSearchInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <ButtonGroup>
                            <Button color="warning" onClick={handleApplySearch}>Aplicar</Button>
                            <Button color="secondary" onClick={handleClearFilters}>Reiniciar</Button>
                        </ButtonGroup>
                    </div>
                    <div className="float-end">
                        <Button color="primary" tag={Link} to="/customers/new">Añadir cliente</Button>
                    </div>
                </div>
                <Table className="mt-4 table-striped table-hover">
                    <thead>
                    <tr>
                        <th width="8%">Nombre</th>
                        <th width="12%">Apellidos</th>
                        <th width="10%">Teléfono</th>
                        <th width="10%">Email</th>
                        <th width="6%">Edad</th>
                        <th width="8%">DNI</th>
                        <th width="8%">Pasaporte</th>
                        <th width="8%">Fecha de nacimiento</th>
                        <th width="8%"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {customerList()}
                    </tbody>
                </Table>
                <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
            </Container>
            {openAudit && (
                <AuditResponseWidget
                    entity='customer'
                    id={selectedCustomerId}
                    onClose={() => setOpenAudit(false)}
                />
            )}
            {showAttachment && (
                <Attachment
                    entity='customer'
                    id={selectedCustomerId}
                    onClose={() => setShowAttachment(false)}
                />
            )}
        </div>
    );
};

export default CustomerList;
