import React from 'react';
import PropTypes from "prop-types";
import PaginationButtons from "./PaginationButtons";
import './Pagination.css';

export const defaultPageParams = {
    pageNumber: 0,
    pageSize: 20,
    totalPages: 0,
    totalElements: 0,
}

export const defaultPageParamsSizeTen = {
    pageNumber: 0,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
}

const PaginationContainer = ({pageInfo, handlePageChange}) => {
    return (
        <div className="pagination-container">
            <div className="pagination-info">
                <span>Página: {pageInfo.pageNumber + 1} de {pageInfo.totalPages !== 0 ? pageInfo.totalPages : 1}</span>
                <br/><span>|</span><br/>
                <span>Total: {pageInfo.totalElements}</span>
            </div>
            <PaginationButtons pageInfo={pageInfo} handlePageChange={handlePageChange}/>
        </div>
    );
};

PaginationContainer.propTypes = {
    pageInfo: PropTypes.shape({
        totalPages: PropTypes.number.isRequired,
        totalElements: PropTypes.number.isRequired,
        pageNumber: PropTypes.number.isRequired,
    }).isRequired,
    handlePageChange: PropTypes.func.isRequired,
};

export default PaginationContainer;
