import React, {useState} from 'react'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import PropTypes from "prop-types";
import {getScheduleModel} from "./ScheduleModel";
import {useFetchData} from "../http/HttpUtil";
import {Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter} from "reactstrap";
import {atStartDay, createDateWithoutTimezone} from "../common/Utils";
import {useAuth} from "../auth/AuthContext";
import {Circle} from "react-color/lib/components/circle/Circle";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {deleteSchedule, upsertSchedule} from "../service/ScheduleRestService";
import CharacterCounter from "../common/CharacterCounter";

require('moment/locale/es')

const localizer = momentLocalizer(moment);

const ShowEvent = ({event, byUser}) => (
    <div>
        {byUser ? (
            <span>{event.title}</span>
        ) : (
            <span>{`${event.userId} - ${event.title}`}</span>
        )}
    </div>
);

function getShowEvent(byUser) {
    return (props) => <ShowEvent {...props} byUser={byUser}/>;
}

const ScheduleCalendar = ({events = [], byUser = false}) => {
    const fetchData = useFetchData();
    const [selectedDate, setSelectedDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newEvent, setNewEvent] = useState(getScheduleModel());
    const [colorHex, setColorHex] = useState('#F44E3B');
    const {blockClicks, unblockClicks} = useClickBlock();
    const {user} = useAuth();

    function isAllDay(start, end) {
        return start.getHours() === 0 && start.getMinutes() === 0
            && end.getHours() === 0 && end.getMinutes() === 0;
    }

    const handleSelectSlot = (slotInfo) => {
        const start = slotInfo.start;
        let end = slotInfo.end;
        setSelectedDate({start: start, end: end});
        setNewEvent(prevState => ({
            ...prevState,
            start: start,
            end: end,
            allDay: isAllDay(start, end),
            userId: user
        }));
        setShowModal(true);
    };

    const handleEdit = (event) => {
        setSelectedDate({start: event.start, end: event.end});
        setNewEvent(event);
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewEvent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (date, fieldName) => {
        setSelectedDate(prevState => ({
            ...prevState,
            [fieldName]: new Date(date),
        }));

        setNewEvent(prevState => ({
            ...prevState,
            [fieldName]: moment(date).format("YYYY-MM-DD"),
        }));
    };

    const handleDateTimeChange = (dateTime, fieldName) => {
        setSelectedDate(prevState => ({
            ...prevState,
            [fieldName]: new Date(dateTime),
        }));

        setNewEvent(prevState => ({
            ...prevState,
            [fieldName]: moment(dateTime).format("YYYY-MM-DDTHH:mm"),
        }));
    };

    const handleCheckboxChange = (e) => {
        const {name, checked} = e.target;
        setNewEvent(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleChangeColor = (color) => {
        setNewEvent(prevState => ({
            ...prevState,
            'color': color.hex
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewEvent(getScheduleModel());
    }

    const handleSaveEvent = () => {
        const startDate = newEvent.allDay ? atStartDay(selectedDate.start) : createDateWithoutTimezone(selectedDate.start);
        const endDate = newEvent.allDay ? atStartDay(selectedDate.end) : createDateWithoutTimezone(selectedDate.end);

        const eventData = {
            id: newEvent.id,
            userId: newEvent.userId,
            title: newEvent.title,
            description: newEvent.description,
            start: startDate,
            end: endDate,
            allDay: newEvent.allDay,
            completed: newEvent.completed,
            color: newEvent.color
        };

        handleSubmit(eventData)
            .then(() => handleCloseModal())
            .then(() => window.location.reload());
    };

    const handleSubmit = async (event) => {
        blockClicks();
        await upsertSchedule(fetchData, event);
        unblockClicks();
    };

    const remove = async (id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este evento?');

        if (confirmDelete) {
            blockClicks();
            await deleteSchedule(fetchData, id)
                .then(() => handleCloseModal())
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    }

    function getNewScheduleModalDates() {
        return <>
            <Label>Desde</Label>
            {newEvent.allDay ? (
                <Input
                    type="date"
                    name="startDate"
                    value={selectedDate.start ? moment(selectedDate.start).format("YYYY-MM-DD") : ''}
                    onChange={e => handleDateChange(e.target.value, 'start')}/>
            ) : (
                <Input
                    type="datetime-local"
                    name="startDateTime"
                    value={selectedDate.start ? moment(selectedDate.start).format("YYYY-MM-DDTHH:mm") : ''}
                    onChange={e => handleDateTimeChange(e.target.value, 'start')}/>
            )}
            <Label>Hasta</Label>
            {newEvent.allDay ? (
                <Input
                    type="date"
                    name="endDate"
                    value={selectedDate.end ? moment(selectedDate.end).format("YYYY-MM-DD") : ''}
                    onChange={e => handleDateChange(e.target.value, 'end')}/>
            ) : (
                <Input
                    type="datetime-local"
                    name="endDateTime"
                    value={selectedDate.end ? moment(selectedDate.end).format("YYYY-MM-DDTHH:mm") : ''}
                    onChange={e => handleDateTimeChange(e.target.value, 'end')}/>
            )}
        </>;
    }

    function getNewScheduleModal() {
        return <Modal isOpen={showModal} toggle={() => handleCloseModal()}>
            <ModalBody>
                <FormGroup>
                    <Label>Título</Label>
                    <Input
                        type="text"
                        name="title"
                        value={newEvent.title}
                        onChange={handleInputChange}
                        maxLength={255}
                    />
                    <CharacterCounter
                        currentLength={newEvent.title?.length}
                        maxLength={255}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Descripción (opcional)</Label>
                    <Input
                        type="textarea"
                        name="description"
                        value={newEvent.description}
                        onChange={handleInputChange}
                        maxLength={4000}
                    />
                    <CharacterCounter
                        currentLength={newEvent.description?.length}
                        maxLength={4000}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Usuario</Label>
                    <Input
                        type="text"
                        name="userId"
                        value={newEvent.userId}
                        onChange={handleInputChange}/>
                </FormGroup>
                <FormGroup>
                    {getNewScheduleModalDates()}
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="allDay"
                            checked={newEvent.allDay}
                            onChange={handleCheckboxChange}/>
                        {'Todo el día'}
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="completed"
                            checked={newEvent.completed}
                            onChange={handleCheckboxChange}/>
                        Completado
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label>Escoge un color</Label>
                    <Circle
                        color={colorHex}
                        onChange={(color) => {
                            setColorHex(color.hex);
                            handleChangeColor(color);
                        }}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success"
                        disabled={!newEvent.title || !newEvent.start
                            || (!newEvent.allDay && !newEvent.end)
                            || selectedDate.start > selectedDate.end}
                        onClick={handleSaveEvent}>
                    {newEvent.id ? 'Guardar' : 'Añadir'}
                </Button>
                {newEvent.id && (
                    <Button color="danger"
                            onClick={() => remove(newEvent.id)}>Eliminar</Button>
                )}
                <Button color="white"
                        onClick={() => handleCloseModal()}>X</Button>
            </ModalFooter>
        </Modal>
            ;
    }

    const eventStyleGetter = (event) => {
        let backgroundColor;

        if (event.color) {
            backgroundColor = event.color;
        } else {
            backgroundColor = event.completed ? 'lightblue' : '';
        }

        return {
            style: {
                backgroundColor,
                textDecoration: event.completed ? 'line-through' : 'none',
            },
        };
    };

    return (
        <div>
            <ClickBlockOverlay/>
            <Container fluid>
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={events}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        event: getShowEvent(byUser)
                    }}
                    style={{height: 700}}
                    messages={{
                        next: "Siguiente",
                        previous: "Anterior",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día",
                        date: "Fecha",
                        time: "Hora",
                        event: "Evento",
                        allDay: "Todo el día",
                        work_week: 'Semana laborable',
                        yesterday: 'Ayer',
                        tomorrow: 'Hoy',
                        agenda: 'Agenda',
                        noEventsInRange: 'No hay eventos en este rango.'
                    }}
                    selectable
                    scrollToTime={new Date()}
                    onSelectEvent={(event) => handleEdit(event)}
                    onSelectSlot={handleSelectSlot}
                />
                {showModal && getNewScheduleModal()}
            </Container>
        </div>
    );
};

ShowEvent.propTypes = {
    event: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired,
        allDay: PropTypes.bool.isRequired,
        completed: PropTypes.bool.isRequired,
        color: PropTypes.string,
    }),
    byUser: PropTypes.bool.isRequired
};

ScheduleCalendar.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            start: PropTypes.instanceOf(Date).isRequired,
            end: PropTypes.instanceOf(Date).isRequired,
            allDay: PropTypes.bool.isRequired,
            completed: PropTypes.bool.isRequired,
            color: PropTypes.string,
        })
    ),
    byUser: PropTypes.bool.isRequired
};

export default ScheduleCalendar;
