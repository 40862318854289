import {Button, ButtonGroup, Table} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {getBudgetDetailType} from "./BudgetDetailModel";
import {Link} from "react-router-dom";
import {formatTimestampToDate} from "../../common/Utils";

const BudgetDetailIncident = ({budgetDetail, getKey, remove,}) => {

    const budgetDetailTypes = getBudgetDetailType();

    return (
        <div>
            <Table className="mt-4 table-hover">
                <thead>
                <tr>
                    <td width="8%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <strong>FECHA</strong>
                    </td>
                    <td width="80%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <strong>OBSERVACIONES / INCIDENCIAS</strong>
                    </td>
                    <td width="12%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <Button size="sm" color="primary" tag={Link}
                                to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.INCIDENT, budgetDetailTypes)}`}>
                            Añadir</Button>
                    </td>
                </tr>
                </thead>
                <tbody>
                {budgetDetail.incidents?.slice()
                    .sort((a, b) => a.date.localeCompare(b.date))
                    .map(incident => (
                        <tr key={incident.id}>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{formatTimestampToDate(incident.date)}</td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{incident.comment}</td>
                            <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                <ButtonGroup>
                                    <Button size="sm" color="warning" tag={Link}
                                            to={`/budgets/${budgetDetail.id}/details/${incident.id}?type=${getKey(budgetDetailTypes.INCIDENT, budgetDetailTypes)}`}>Editar</Button>
                                    <Button size="sm" color="danger"
                                            onClick={() => remove(budgetDetailTypes.INCIDENT, incident.id)}>Eliminar</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailIncident.propTypes = {
    budgetDetail: PropTypes.shape({
        id: PropTypes.any.isRequired,
        incidents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.any.isRequired,
                date: PropTypes.any.isRequired,
                comment: PropTypes.any.isRequired,
            })
        ).isRequired,
    }).isRequired,
    getKey: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
};

export default BudgetDetailIncident;
