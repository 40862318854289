// App.js
import React, {Suspense, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';
import BudgetList from './budget/BudgetList';
import BudgetEdit from './budget/BudgetEdit';
import Login from './login/Login';
import {AuthProvider} from "./auth/AuthContext";
import AppNavbar from "./navbar/AppNavbar";
import Logout from "./login/Logout";
import PlanningList from "./planning/PlanningList";
import PlanningEdit from "./planning/PlanningEdit";
import BudgetDetailList from "./budget/detail/BudgetDetailList";
import BudgetDetailEdit from "./budget/detail/BudgetDetailEdit";
import CustomerList from "./customer/CustomerList";
import CustomerEdit from "./customer/CustomerEdit";
import ScheduleView from "./schedule/ScheduleView";
import NotesWrapper from "./note/NotesWrapper";
import VersionCheck from "./util/VersionCheck";
import {ClickBlockProvider} from "./context/ClickBlockContext";
import Spinner from "./common/Spinner";

const App = () => {
    const [notesMinimized, setNotesMinimized] = useState(true);

    const toggleMinimizeNotes = () => {
        setNotesMinimized(!notesMinimized);
    };

    return (
        <AuthProvider>
            <VersionCheck/>
            <ClickBlockProvider>
                <Router>
                    <AppNavbar/>
                    <div className={`main-content ${notesMinimized ? 'full-width' : ''}`}>
                        <Suspense fallback={<Spinner/>}>
                            <Routes>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/logout/*" element={<Logout/>}/>
                                <Route path="/budgets" element={<BudgetList/>}/>
                                <Route path="/plannings" element={<PlanningList/>}/>
                                <Route path="/customers" element={<CustomerList/>}/>
                                <Route path="/agenda" element={<ScheduleView/>}/>
                                <Route path="/budgets/:id" element={<BudgetEdit/>}/>
                                <Route path="/plannings/:id" element={<PlanningEdit/>}/>
                                <Route path="/customers/:id" element={<CustomerEdit/>}/>
                                <Route path="/budgets/:id/details" element={<BudgetDetailList/>}/>
                                <Route path="/budgets/:id/details/:detailId" element={<BudgetDetailEdit/>}/>
                                <Route path="*" element={<Home/>}/>
                            </Routes>
                        </Suspense>
                    </div>
                    <NotesWrapper minimized={notesMinimized} toggleMinimize={toggleMinimizeNotes}/>
                </Router>
            </ClickBlockProvider>
        </AuthProvider>
    );
};

export default App;
