import React from 'react';
import PropTypes from 'prop-types';

function CharacterCounter({currentLength, maxLength}) {
    return (
        <div style={{fontSize: '0.9em', color: 'gray', marginTop: '5px'}}>
            {currentLength}/{maxLength} caracteres
        </div>
    );
}

CharacterCounter.propTypes = {
    currentLength: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired
};

export default CharacterCounter;
