import {BASE_API_URL} from "./RestService";

export function getCustomers(fetchData, queryParams) {
    return fetchData(`${BASE_API_URL}/customer/resources?${queryParams.toString()}`, {});
}

export function getCustomer(fetchData, id) {
    return fetchData(`${BASE_API_URL}/customer/${id}`, {});
}

export function upsertCustomer(fetchData, customer) {
    return fetchData(`${BASE_API_URL}/customer`, {
        method: customer.id ? 'PUT' : 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(customer),
    });
}

export function linkCustomers(fetchData, expedientId, selectedCustomerIds) {
    return fetchData(`${BASE_API_URL}/expedient/${expedientId}/link-customers`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({customerIds: selectedCustomerIds}),
    });
}

