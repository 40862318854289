import {useEffect} from 'react';

function updateAppVersionAndReload(newVersion) {
    localStorage.setItem('appVersion', newVersion);
    window.location.reload();
}

function clearAllCaches(cacheNames, newVersion) {
    Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)))
        .then(() => {
            updateAppVersionAndReload(newVersion);
        })
        .catch(error => console.error('Error clearing caches:', error));
}

const VersionCheck = () => {
    useEffect(() => {
        // Fetch the manifest.json file to check the current app version
        fetch('/manifest.json', {cache: 'no-cache'})
            .then(response => response.json())
            .then(data => {
                const currentVersion = localStorage.getItem('appVersion');
                const newVersion = data.version;

                // If there's a version mismatch, reload the page and update the stored version
                if (currentVersion !== newVersion) {
                    console.log(`New version detected: ${newVersion}. Clearing cache and reloading...`);

                    // Clear all caches
                    if ('caches' in window) {
                        caches.keys().then((cacheNames) => clearAllCaches(cacheNames, newVersion));
                    } else {
                        updateAppVersionAndReload(newVersion);
                    }
                }
            })
            .catch(error => console.error('Error fetching version info:', error));
    }, []);

    return null; // This component doesn't render anything
};

export default VersionCheck;
